import { useOtherLocale, useOtherLocaleUrl } from '@dreamstack/i18n'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import { linkHoverStyles } from './NavBar'

export const LocaleSwitcher: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const otherLocale = useOtherLocale()
  const slugInOtherLocale = useOtherLocaleUrl()

  if (!slugInOtherLocale || !otherLocale) return null

  const prefix = !slugInOtherLocale.startsWith('/') ? '/' : ''
  const href = `${prefix}${slugInOtherLocale}`

  return (
    <>
      {/* TODO: find out "/" at the start of path href={`/${slugInOtherLocale}`} */}
      <Link
        href={href}
        locale={otherLocale}
        passHref
        css={linkHoverStyles}
        tw="rounded-none cursor-pointer inline-block border-b-2 border-b-transparent font-semibold z-10"
      >
        {otherLocale.toUpperCase()}
      </Link>
    </>
  )
}
