import { useMemo } from 'react'
import {
  useIsLgOrGreater,
  useIsMdOrGreater,
  useIsMlOrGreater,
  useIsSmOrGreater,
  useIsSmOrSmaller,
} from './useWindowSize'

export const useResponsiveHeroVariants = () => {
  const isLg = useIsLgOrGreater()
  const isMl = useIsMlOrGreater()
  const isMd = useIsMdOrGreater()
  const isSm = useIsSmOrGreater()
  const isMobile = useIsSmOrSmaller()

  // last value is evertime for SSR
  const lgHero = isLg ? 1080 : isMd ? 576 : isSm ? 480 : isMobile ? 480 : 1080
  const mdHero = isLg ? 860 : isMd ? 576 : isSm ? 320 : isMobile ? 320 : 860
  const smHero = isMd ? 480 : isSm ? 320 : isMobile ? 320 : 480
  const intHero = isMd ? 798 : isSm ? 480 : isMobile ? 480 : 798
  const campHero = isMl ? 798 : isMd ? 503 : isSm ? 320 : isMobile ? 320 : 798

  return useMemo(() => {
    return {
      large: lgHero,
      medium: mdHero,
      small: smHero,
      international: intHero,
      campaign: campHero,
    }
  }, [lgHero, mdHero, smHero, intHero, campHero])
}
