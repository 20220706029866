import Link from 'next/link'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import { IS_ACCENTRO_WEB } from '../index'
import { NavbarLogoSvg } from './NavbarLogoSvgComponent'

// FROM: https://codepen.io/sosuke/pen/Pjoqqp (#508090)
// TODO: fix types
const Logo: any = styled(NavbarLogoSvg)(
  ({ $forceDarkTextColor }: { $forceDarkTextColor: boolean }) => [
    tw`text-accentroWhite-full`,
    $forceDarkTextColor && tw`text-accentroGray-full`,
    $forceDarkTextColor && IS_ACCENTRO_WEB && tw`text-accentroAqua-full`,
  ]
)

export const NavBarLogo: FunctionComponent<React.PropsWithChildren<{
  forceDarkTextColor: boolean
  href?: string
}>> = ({ forceDarkTextColor, href = '/' }) => {
  return (
    <div tw="z-10">
      <Link href={href} passHref>
        <div tw="width[153px] sm:w-px-196 md:width[230px] flex">
          <Logo $forceDarkTextColor={forceDarkTextColor} />
        </div>
      </Link>
    </div>
  )
}
