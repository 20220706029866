import type { Maybe } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import { createContext, useContext } from 'react'

type PropertyTracking = {
  property_id: number
  title: string | undefined | null
  property_address: string | undefined | null
}

type ProjectTracking = {
  projectId: number
}

type PropertyReservationTracking = {
  propertyId: string
  reservationTool: boolean
}

type ReservationIndexTracking = {
  reservationTool: boolean
}

type StaticPageTracking = {
  slug: string
  staticPageId: string | undefined
  propertyId: number | undefined
}

type LexiconTracking = {
  slug: Maybe<string> | undefined
  lexiconLetter: Maybe<string> | undefined
  contentfulId: string
}

type NewsArticleTracking = {
  newsType: Maybe<string> | undefined
  slug: Maybe<string> | undefined
  articleId: string
}

export type IAdditionalTrackingInfoContext =
  | PropertyTracking
  | ProjectTracking
  | PropertyReservationTracking
  | ReservationIndexTracking
  | StaticPageTracking
  | LexiconTracking
  | NewsArticleTracking

const AdditionalTrackingInfoContext =
  createContext<IAdditionalTrackingInfoContext | null>(null)

export const AdditionalTrackingInfoContextProvider: FunctionComponent<React.PropsWithChildren<{
  context: IAdditionalTrackingInfoContext
}>> = ({ children, context }) => {
  return (
    <AdditionalTrackingInfoContext.Provider value={context}>
      {children}
    </AdditionalTrackingInfoContext.Provider>
  )
}

export const useAdditionalTrackingInfoContext = () => {
  return useContext(AdditionalTrackingInfoContext)
}
