import { useOtherLocale, useOtherLocaleUrlsContext } from '@dreamstack/i18n'
import last from 'lodash/last'
import split from 'lodash/split'
import { useRouter } from 'next/router'

export const useOtherLocaleUrl = () => {
  const { asPath } = useRouter()
  const otherLocale = useOtherLocale()
  const otherLocaleUrls = useOtherLocaleUrlsContext()
  if (!otherLocale) {
    return null
  }
  const otherLocaleUrl = otherLocaleUrls && otherLocaleUrls[otherLocale]

  // return null if no other localeUrl exists
  // (also dont fallback, because the info was explicitly provided that no otherLocale does not exist)
  if (otherLocaleUrls && !otherLocaleUrls[otherLocale]) {
    return null
  }

  const [pathName, queryString] = split(last(split(asPath, '/')), '?')

  let url = otherLocaleUrl ? otherLocaleUrl.url : pathName

  if (queryString) {
    url = `${url}?${queryString}`
  }

  return url
}
