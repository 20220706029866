import { useTranslation } from '@dreamstack/i18n'
import type { FullNavBarEntryFragment } from '@dreamstack/investors-graphql'
import { trpc } from '@dreamstack/trpc/client'
import { Popover } from '@headlessui/react'
import { motion, useAnimation } from 'framer-motion'
import map from 'lodash/map'
import some from 'lodash/some'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import tw, { styled } from 'twin.macro'
import { IS_ACCENTRO_WEB } from '../config/config'
import { useComingSoonSlug } from '../hooks/useComingSoonSlug'
import { useLexiconSlug } from '../hooks/useLexiconSlug'
import { useNavbarDisplaySettings } from '../hooks/useNavbarDisplaySettings'
import { useProjectsSlug } from '../hooks/useProjectsSlug'
import { useScrollDirection } from '../hooks/useScrollDirection'
import { StyledNavText, StyledSubNavText } from './NavBar'

export const StyledNavEntry = styled.a(() => [
  tw`px-px-32 py-px-12 block cursor-pointer`,
  `&:hover {background-color: rgba(255, 255, 255, 0.05);}`,
])

export const MotionPanel = motion(Popover.Panel)

const StyledMotionPanel = styled(MotionPanel)<{ $alignRight?: boolean }>(
  ({ $alignRight }) => [
    tw`absolute width[296px] z-10`,
    $alignRight && tw`right-0`,
  ]
)

export const ExtraNavbarEntry: FunctionComponent<
  React.PropsWithChildren<{
    slug: string
    title: string
  }>
> = ({ slug, title }) => {
  const { asPath } = useRouter()
  return (
    <Link href={`/${slug}`} passHref legacyBehavior>
      <StyledNavEntry className="group">
        <StyledSubNavText isActive={asPath.endsWith(slug)}>
          {title}
        </StyledSubNavText>
      </StyledNavEntry>
    </Link>
  )
}
//this needs to be in a separate component, otherwise there is an undefined if we click on the link
export const ExtraNavbarEntryToInternational: FunctionComponent<
  React.PropsWithChildren<{
    href: string
    title: string
  }>
> = ({ href, title }) => {
  return (
    <Link href={`${href}`} passHref legacyBehavior>
      <StyledNavEntry className="group">
        <StyledSubNavText isActive={false}>{title}</StyledSubNavText>
      </StyledNavEntry>
    </Link>
  )
}

// TODO: typings.
// Hover related stuff inspired by https://codesandbox.io/s/d8llw?file=/src/App.js
export const EntryWithSubNavbar: FunctionComponent<
  React.PropsWithChildren<{
    entry: FullNavBarEntryFragment
    alignRight?: boolean
    preNode?: React.ReactNode
    postNode?: React.ReactNode
    shouldRoute?: boolean
  }>
> = ({ entry, alignRight, preNode, postNode, shouldRoute = true }) => {
  const { asPath } = useRouter()
  const { isScrollingDown } = useScrollDirection()
  const t = useTranslation()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [openState, setOpenState] = useState(false)
  const { data: comingSoonProjects } =
    trpc.project.getComingSoonProjects.useQuery(
      {
        locale: 'de',
      },
      {
        trpc: { ssr: true },
        staleTime: Infinity,
        enabled: IS_ACCENTRO_WEB,
      }
    )
  const comingSoonProjectsExist =
    !!comingSoonProjects && comingSoonProjects?.length > 0

  useEffect(() => {
    setOpenState(false)
  }, [isScrollingDown])

  const onHover = (action: 'onMouseEnter' | 'onMouseLeave') => {
    if (!isMobile) {
      if (action === 'onMouseEnter') setOpenState(true)
      if (action === 'onMouseLeave') setOpenState(false)
    }
  }

  const handleClick = () => {
    setOpenState((openState) => !openState)
  }

  // TODO: Fix typing for click event.
  const handleClickOutside = (event: any) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      event.stopPropagation()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown_' + entry.label, handleClickOutside)

    return () => {
      document.removeEventListener(
        'mousedown_' + entry.label,
        handleClickOutside
      )
    }
  })

  const controls = useAnimation()

  useEffect(() => {
    const animate = async () => {
      if (openState) {
        controls.start('displayed')
      } else {
        await controls.start('fadeOut')
        await controls.start('hidden')
      }
    }
    animate()
  }, [openState, controls])

  const entrySlug = entry?.staticPage?.slug

  const isActive =
    !!entrySlug &&
    (asPath.endsWith(entrySlug) ||
      some(entry?.subEntriesCollection?.items, (item) => {
        const itemSlug = item?.staticPage?.slug
        return itemSlug && asPath.endsWith(itemSlug)
      }))

  const {
    displayDefaultSubEntry,
    displayProjectsSubEntry,
    displayLexiconSubEntry,
    displayInternationalSubEntry,
  } = useNavbarDisplaySettings(entry)
  const projectsSlug = useProjectsSlug()
  const lexiconSlug = useLexiconSlug()
  const comingSoonSlug = useComingSoonSlug()
  const finalHref = entrySlug ? `/${entrySlug}` : entry?.url ?? '#'
  return (
    <Popover tw="relative focus-visible:outline-none">
      {({ open }) => (
        <div
          onMouseEnter={() => onHover('onMouseEnter')}
          onMouseLeave={() => onHover('onMouseLeave')}
        >
          <Popover.Button
            ref={buttonRef}
            tw="font-semibold focus-visible:outline-none cursor-default"
            onClick={() => handleClick()}
          >
            {shouldRoute ? (
              <Link href={finalHref} passHref tw="cursor-default">
                <StyledNavText isActive={isActive}>{entry.label}</StyledNavText>
              </Link>
            ) : (
              <a tw="cursor-default">
                <StyledNavText isActive={isActive}>{entry.label}</StyledNavText>
              </a>
            )}
          </Popover.Button>
          <StyledMotionPanel
            $alignRight={alignRight}
            key={'parentPanel_' + entry.sys.id}
            static
            tw=""
            initial="hidden"
            animate={controls}
            variants={{
              fadeOut: {
                opacity: 0,
                translateY: '10px',
                transition: {
                  duration: 0.15,
                },
              },
              displayed: {
                opacity: 1,
                visibility: 'unset',
                translateY: '0px',
              },
              hidden: {
                visibility: 'hidden',
                translateY: '10px',
              },
            }}
            transition={{ duration: 0.2 }}
          >
            <div
              tw="mt-px-20"
              style={{ boxShadow: '0 2px 6px 0 rgba(43, 45, 54, 0.5)' }}
            >
              <div tw="bg-accentroGray-full py-px-20 ">
                {preNode}
                {displayDefaultSubEntry && (
                  <Link href={finalHref} passHref legacyBehavior>
                    <StyledNavEntry className="group">
                      <StyledSubNavText
                        isActive={
                          !!entry?.staticPage?.slug &&
                          asPath.endsWith(entry?.staticPage?.slug)
                        }
                      >
                        {t('accentro:navigation.overview')}
                      </StyledSubNavText>
                    </StyledNavEntry>
                  </Link>
                )}
                {map(entry?.subEntriesCollection?.items, (subEntry) => {
                  const isActive =
                    !!subEntry?.staticPage?.slug &&
                    asPath.endsWith(subEntry?.staticPage?.slug)
                  const finalHref = subEntry?.staticPage?.slug
                    ? `/${subEntry?.staticPage?.slug}`
                    : subEntry?.url ?? '#'
                  return (
                    <Link
                      key={subEntry?.sys.id}
                      href={finalHref}
                      passHref
                      legacyBehavior
                    >
                      <StyledNavEntry className="group">
                        <StyledSubNavText isActive={isActive}>
                          {subEntry?.label}
                        </StyledSubNavText>
                      </StyledNavEntry>
                    </Link>
                  )
                })}
                {displayProjectsSubEntry && (
                  <>
                    <ExtraNavbarEntry
                      slug={projectsSlug}
                      title={t('accentro:navigation.projects')}
                    />
                    {comingSoonProjectsExist && (
                      <ExtraNavbarEntry
                        slug={comingSoonSlug}
                        title={t('accentro:navigation.comingSoon')}
                      />
                    )}
                  </>
                )}
                {displayLexiconSubEntry && (
                  <ExtraNavbarEntry
                    slug={lexiconSlug}
                    title={t('accentro:navigation.lexicon')}
                  />
                )}
                {displayInternationalSubEntry && (
                  <ExtraNavbarEntryToInternational
                    href={`https://international.accentro.de/`}
                    title={t('accentro:navigation.internationalVisitors')}
                  />
                )}
                {postNode}
              </div>
            </div>
          </StyledMotionPanel>
        </div>
      )}
    </Popover>
  )
}
