import { useEffect, useMemo, useState } from 'react'

// https://stackoverflow.com/a/65008608
// refactored
export const useOnScreen = (ref: { current: HTMLElement | null }) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const intersectionObserver = useMemo(
    () =>
      typeof window !== 'undefined'
        ? new window.IntersectionObserver(([entry]) =>
            setIntersecting(entry.isIntersecting)
          )
        : null,
    []
  )

  useEffect(() => {
    if (!intersectionObserver || !ref.current) return

    intersectionObserver.observe(ref.current)

    // Remove the observer as soon as the component is unmounted
    return () => {
      intersectionObserver.disconnect()
    }
  }, [intersectionObserver, ref])

  return isIntersecting
}
