import { useSessionTyped } from './useSession'

export const useIsLoggedInWithLoading = () => {
  const session = useSessionTyped()

  return {
    loading: session === undefined,
    isLoggedIn: !!session,
  }
}
