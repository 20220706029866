import { LinearProgress, withStyles } from '@material-ui/core'
import { Router } from 'next/router'
import type {
  FunctionComponent} from 'react';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import tw from 'twin.macro'

const ProgressContainer = tw.div`fixed top-0 w-screen z-50`
const StyledLinearProgress = withStyles(() => ({
  colorPrimary: {
    backgroundColor: '#508090',
  },
  bar: {
    backgroundColor: '#d3dfe3',
  },
}))(LinearProgress)

type RouteChangeState = 'hide' | 'waiting' | 'show'

export const PageLoadIndicator: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  //track state of current route changing
  const [routeChanging, setRouteChanging] = useState<RouteChangeState>('hide')
  //make timeout accessible to unsub if component unloads during page transition (cannot set State in unmounted component error)
  const timeoutRef = useRef<NodeJS.Timeout>()
  //create ref to access latest state in setTimeout fn closure
  const routeStateRef = useRef(routeChanging)
  routeStateRef.current = routeChanging

  const onRouteChange = useCallback(() => {
    //get into waiting state (actual showing can be denied if page load finishes earlier than the set timeout)
    setRouteChanging('waiting')
    timeoutRef.current = setTimeout(() => {
      //if still in waiting state show loading indicator
      if (routeStateRef.current === 'waiting') {
        setRouteChanging('show')
      }
    }, 500)
  }, [setRouteChanging])

  const onRouteFinish = useCallback(() => {
    setRouteChanging('hide')
  }, [setRouteChanging])

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChange)
    Router.events.on('routeChangeComplete', onRouteFinish)
    Router.events.on('routeChangeError', onRouteFinish)
    return () => {
      //unsub all events and clear timeout if exists
      Router.events.off('routeChangeStart', onRouteChange)
      Router.events.off('routeChangeComplete', onRouteFinish)
      Router.events.off('routeChangeError', onRouteFinish)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [onRouteChange, onRouteFinish])
  return routeChanging === 'show' ? (
    <ProgressContainer>
      <StyledLinearProgress />
    </ProgressContainer>
  ) : (
    <></>
  )
}
