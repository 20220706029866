import { useAuthModalContext, useUserId } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { Clear, Menu } from '@dreamstack/icons'
import type { FullNavBarEntryFragment } from '@dreamstack/investors-graphql'
import type { AnimationControls } from 'framer-motion'
import { motion, useAnimation } from 'framer-motion'
import map from 'lodash/map'
import { signOut } from 'next-auth/react'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import 'twin.macro'
import tw, { styled } from 'twin.macro'
import {
  IS_ACCENTRO_WEB,
  LocaleSwitcher,
  linkHoverStyles,
  subnavActiveStyle,
} from '..'
import { useMyAccentroNavEntry } from '../hooks/useMyAccentroNavEntry'
import { useGetInvestorsLocaleForLink } from '../index'
import { MobileEntryWithSubNavbar } from './MobileEntryWithSubNavbar'
import { NavBarLogo } from './NavBarLogo'
import { SimpleMuiButton } from './SimpleMuiButton'

export const NavDivider: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  return (
    <motion.div
      variants={MobileNavItemVariants}
      key={'divider'}
      tw="height[2px] bg-accentroWhite-50"
    />
  )
}

export const MobileNavEntriesWrapper = styled(motion.div)(() => [
  tw`flex flex-col px-px-24 pb-px-32 h-full width[360px] overflow-y-auto box-shadow[inset] relative`,
  `&::-webkit-scrollbar {
  display: none;
}`,
  `-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */`,
])

export const StyledMobileLink = styled.a<{ isActive?: boolean }>(({}) => [
  tw`font-semibold text-px-16 leading-px-24 border-b-2 border-transparent sm:(text-px-20 leading-px-30)`,
  linkHoverStyles,
])

export const StyledMobileText = styled.span<{ isActive?: boolean }>(
  ({ isActive }) => [
    tw`font-semibold text-px-16 leading-px-24 border-2 border-transparent border-b-transparent hover:border-b-accentroWhite-full sm:(text-px-20 leading-px-30)`,
    isActive && subnavActiveStyle,
  ]
)

const MobileNavItemVariants = {
  mobileNavOpen: {
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  mobileNavClosed: {
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export const mobileFadeOutVariants = {
  fadeOut: {
    opacity: 0,
    translateX: '-30px',
    transition: {
      duration: 0.15,
    },
  },
  displayed: {
    translateX: 0,
    opacity: 1,
    transition: {
      duration: 0.15,
    },
  },
}

const MobilNavBar = styled(motion.div)<{
  $forceDarkTextColor: boolean
}>(({ $forceDarkTextColor }) => [
  tw`w-full z-10 fixed top-0`,
  !$forceDarkTextColor && tw`text-white`,
])

const MobileNavItems = styled(motion.div)(() => [
  tw`flex flex-col justify-between h-full`,
])

export const MobileNavBar: FunctionComponent<
  React.PropsWithChildren<{
    standalone?: boolean
    forceDark: boolean
    mobileNavOpen: boolean
    entries: FullNavBarEntryFragment[]
    setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>
    controls: AnimationControls
  }>
> = ({
  forceDark,
  mobileNavOpen,
  setMobileNavOpen,
  entries,
  controls,
  standalone,
}) => {
  const [fadedOut, setFadedOut] = useState(false)
  const menuControls = useAnimation()
  const { setOpen } = useAuthModalContext()
  const userId = useUserId()

  const myAccentroNavEntry = useMyAccentroNavEntry()

  useEffect(() => {
    setMobileNavOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    if (fadedOut) {
      menuControls.start('fadeOut')
    } else {
      menuControls.start('displayed')
    }
  }, [menuControls, fadedOut])

  useEffect(() => {
    if (!mobileNavOpen) {
      menuControls.start('subNavClosed')
    }
  }, [menuControls, mobileNavOpen])

  const t = useTranslation()
  const locale = useGetInvestorsLocaleForLink()
  let mobileNaviHeight = '100vh'
  if (typeof window !== 'undefined')
    mobileNaviHeight = `${window?.visualViewport?.height}px`

  return (
    <MobilNavBar
      animate={controls}
      transition={{ duration: 0.2 }}
      variants={{
        isAtTop: {
          backgroundColor: '#00000000',
          translateY: 0,
        },
        scrollingDown: {
          translateY: -96,
        },
        scrollingUp: {
          translateY: 0,
          backgroundColor: '#ffffff',
        },
      }}
      $forceDarkTextColor={forceDark && !mobileNavOpen}
    >
      <div tw="flex top-0 w-full justify-between items-center px-px-24 h-px-96 md:(hidden)">
        <NavBarLogo forceDarkTextColor={forceDark && !mobileNavOpen} />
        <motion.div tw="absolute inset-0 flex justify-end items-center pr-px-24">
          <div
            tw="cursor-pointer z-20"
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
            onKeyPress={() => setMobileNavOpen(!mobileNavOpen)}
            role="button"
            tabIndex={0}
          >
            {mobileNavOpen ? (
              <Clear tw="select-none" />
            ) : (
              <Menu tw="select-none" />
            )}
          </div>
          <motion.div
            initial={'mobileNavClosed'}
            animate={mobileNavOpen ? 'mobileNavOpen' : 'mobileNavClosed'}
            variants={{
              mobileNavOpen: (height = 1000) => ({
                clipPath: `circle(${height * 2 + 200}px at top right)`,
                transition: {
                  type: 'just',
                },
                backgroundColor: '#2B2D36',
                height: mobileNaviHeight,
                display: 'flex',
              }),
              mobileNavClosed: {
                clipPath: 'circle(100px at top right)',
                transition: {
                  delay: 0.2,
                  type: 'spring',
                  stiffness: 400,
                  damping: 40,
                },
                backgroundColor: '#2B2D3600',
                height: 'auto',
              },
            }}
            tw="absolute text-accentroWhite-full overflow-hidden inset-0 flex pt-px-96 justify-center items-center ml:(hidden) sm:items-center"
          >
            <MobileNavEntriesWrapper animate={menuControls}>
              <MobileNavItems
                animate={mobileNavOpen ? 'mobileNavOpen' : 'mobileNavClosed'}
                variants={{
                  mobileNavOpen: {
                    transition: { staggerChildren: 0.02, delayChildren: 0.2 },
                  },
                  mobileNavClosed: {
                    transition: { staggerChildren: 0.01, staggerDirection: -1 },
                  },
                }}
              >
                <div tw="space-y-px-16 md:(space-y-px-24)">
                  {!standalone &&
                    map(entries, (entry) => {
                      return (
                        <motion.div
                          variants={MobileNavItemVariants}
                          key={entry.sys.id}
                        >
                          <MobileEntryWithSubNavbar
                            controls={menuControls}
                            setFadedOut={setFadedOut}
                            entry={entry}
                            mobileNavOpen={mobileNavOpen}
                          />
                        </motion.div>
                      )
                    })}
                  {userId && (
                    <motion.div
                      variants={MobileNavItemVariants}
                      key={myAccentroNavEntry.sys.id}
                    >
                      <MobileEntryWithSubNavbar
                        controls={menuControls}
                        setFadedOut={setFadedOut}
                        entry={myAccentroNavEntry}
                        mobileNavOpen={mobileNavOpen}
                      />
                    </motion.div>
                  )}
                  <motion.div
                    animate={menuControls}
                    variants={mobileFadeOutVariants}
                  >
                    <NavDivider />
                  </motion.div>
                  {IS_ACCENTRO_WEB ? (
                    <motion.div
                      key={'investors_nav_item'}
                      variants={MobileNavItemVariants}
                    >
                      <motion.div
                        animate={menuControls}
                        variants={mobileFadeOutVariants}
                      >
                        <Link
                          href={`https://investors.accentro.de/${locale}`}
                          passHref
                          legacyBehavior
                        >
                          <StyledMobileLink target="_blank" isActive={false}>
                            Investor Relations
                          </StyledMobileLink>
                        </Link>
                      </motion.div>
                    </motion.div>
                  ) : (
                    <motion.div
                      key={'accentro_nav_item'}
                      variants={MobileNavItemVariants}
                    >
                      <motion.div
                        animate={menuControls}
                        variants={mobileFadeOutVariants}
                      >
                        <Link
                          href={`https://accentro.de/`}
                          passHref
                          legacyBehavior
                        >
                          <StyledMobileLink target="_blank" isActive={false}>
                            ACCENTRO GmbH
                          </StyledMobileLink>
                        </Link>
                      </motion.div>
                    </motion.div>
                  )}
                </div>
                <motion.div
                  tw="flex justify-between items-center"
                  variants={MobileNavItemVariants}
                  key={'web'}
                >
                  {IS_ACCENTRO_WEB && (
                    <motion.div variants={MobileNavItemVariants} key={'login'}>
                      <motion.div
                        tw="flex justify-between space-x-px-20"
                        animate={menuControls}
                        variants={mobileFadeOutVariants}
                      >
                        {userId ? (
                          <SimpleMuiButton
                            variant="text"
                            color="inherit"
                            onClick={() =>
                              signOut({
                                callbackUrl: `${window.location.origin}`,
                              })
                            }
                          >
                            {t('accentro:logout')}
                          </SimpleMuiButton>
                        ) : (
                          <SimpleMuiButton
                            variant="outlined"
                            color="inherit"
                            onClick={() => {
                              setOpen(true)
                            }}
                          >
                            {t('accentro:login')}
                          </SimpleMuiButton>
                        )}
                      </motion.div>
                    </motion.div>
                  )}
                  <motion.div variants={MobileNavItemVariants} key={'language'}>
                    <motion.div
                      animate={menuControls}
                      variants={mobileFadeOutVariants}
                    >
                      <LocaleSwitcher />
                    </motion.div>
                  </motion.div>
                </motion.div>
              </MobileNavItems>
            </MobileNavEntriesWrapper>
          </motion.div>
        </motion.div>
      </div>
    </MobilNavBar>
  )
}
