import { useMemo } from 'react'
import { useIsSmOrGreater } from '..'
import {
  useIsLgOrGreater,
  useIsMdOrGreater,
  useIsSmOrSmaller,
} from './useWindowSize'

export const useResponsiveNavbarVariants = () => {
  const isLg = useIsLgOrGreater()
  const isMd = useIsMdOrGreater()
  const isSm = useIsSmOrGreater()
  const isMobile = useIsSmOrSmaller()

  // Last case is important for serverSideRendering. It is the default when no sizes are known.
  const height = isLg ? 196 : isMd ? 150 : isSm ? 132 : isMobile ? 128 : 196

  return useMemo(() => {
    return {
      isAtTop: {
        height: height,
      },
      scrollingDown: {
        height: height,
      },
      scrollingUp: {
        height: height,
      },
    }
  }, [height])
}
