import { useLocale } from '@dreamstack/i18n'

const lexiconSlugsForLocale = {
  en: 'knowledge/lexicon',
  de: 'wissen/lexikon',
}

export const useLexiconSlug = () => {
  const locale = useLocale()
  return lexiconSlugsForLocale[locale]
}
