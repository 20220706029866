import { useTranslation } from '@dreamstack/i18n'
import { Mail, Phone } from '@dreamstack/icons'
import { motion } from 'framer-motion'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'

export type HeaderProps = {
  displayOnTop?: boolean
  displayInMobileNav?: boolean
  isShown?: boolean
}

const MyContactContainer = styled(motion.div)<{
  $displayOnTop?: boolean
  $displayInMobileNav?: boolean
}>(({ $displayOnTop, $displayInMobileNav }) => [
  tw`bg-accentroAquaVeryLight-full text-px-13 z-10 leading-px-18 md:py-px-4`,
  $displayOnTop &&
    tw`hidden md:(flex fixed top-0 w-full justify-center items-center space-x-px-16)`,
  $displayInMobileNav && tw` flex-col p-px-16 mt-px-12 -mb-px-16 md:(hidden)`,
])
const SemiBold = tw.span`font-semibold`

export const ContactHeaderForNavBar: FunctionComponent<
  React.PropsWithChildren<HeaderProps>
> = ({ displayInMobileNav, displayOnTop, isShown }) => {
  const t = useTranslation()

  if (isShown) {
    return null
  }

  return (
    <MyContactContainer
      $displayOnTop={displayOnTop}
      $displayInMobileNav={displayInMobileNav}
    >
      <div tw="flex flex-col md:flex-row">
        <SemiBold tw="mr-px-4">{t('accentro:contactHeaderQuestion')}</SemiBold>
        {t('accentro:contactHeaderHelp')}
      </div>
      <div tw="flex mt-px-16 items-center  md:(mt-0)">
        <Phone tw="text-accentroAqua-full h-px-24 w-px-24 mr-px-4" />
        030 - 88 71 81 41
      </div>
      <div tw="hidden items-center md:(flex font-semibold space-x-px-16 mb-px-4)">
        .
      </div>
      <a href="mailto:kundenmanagement@accentro.de">
        <div tw="flex text-accentroAqua-full items-center">
          <Mail tw="h-px-24 w-px-24 mr-px-4" />
          kundenmanagement​@accentro.de
        </div>
      </a>
    </MyContactContainer>
  )
}
