import filter from 'lodash/filter'
import first from 'lodash/first'
import { allLocales } from '../lang/allLocales'
import { useLocale } from './useLocale'

export const useOtherLocale = () => {
  const currentLocale = useLocale()
  const otherLocales = filter(allLocales, (l) => l !== currentLocale)
  const otherLocale = first(otherLocales)
  return otherLocale
}
