import { useSessionTyped } from '@dreamstack/auth'
import { useLocale } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import { useEffect } from 'react'
import { useGaTrackPropstackContactId } from '../googleAnalytics/useGaTrackEvent'
import { getOrCreateAnonymousId } from './getOrCreateAnonymousId'

export const useTrackIdentityEffect = () => {
  const session = useSessionTyped()
  const locale = useLocale()
  const isLoggedIn = !!session

  const { data: propstackContact } = trpc.user.getCurrentUserContact.useQuery(
    undefined,
    { enabled: isLoggedIn }
  )
  const propstackContactId = propstackContact?.contactId

  const { mutateAsync: cioIdentify } = trpc.tracking.cioIdentify.useMutation()

  //TODO: This triggers too often. Every time the effect is fired, customerio thinks some attributes (createdAt) have changed.
  //possible fix: fire this manually after login/registration
  useEffect(() => {
    if (!!session) {
      const anonymousUserId = getOrCreateAnonymousId()
      try {
        cioIdentify({
          userId: session.id,
          locale,
          anonymousUserId,
        })
      } catch (err) {
        console.warn('error identifying userObject: ', err)
      }
    }
  }, [session, cioIdentify, locale])

  //#region Google Analytics
  const trackPropstackContactId = useGaTrackPropstackContactId()
  useEffect(() => {
    if (propstackContactId) {
      trackPropstackContactId({ propstackContactId })
    }
  }, [propstackContactId, trackPropstackContactId])

  //#endregion
}
