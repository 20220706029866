export const CUSTOMER_IO_APP_API_KEY = process.env.CUSTOMER_IO_APP_API_KEY!

export const ENABLE_CUSTOMER_IO =
  process.env.NEXT_PUBLIC_ENABLE_CUSTOMER_IO?.toLowerCase() === 'true'

export const DEBUG_LOG_CIO =
  process.env.NEXT_PUBLIC_DEBUG_LOG_CIO?.toLowerCase() === 'true'

export const CUSTOMER_IO_TRACKING_API_KEY =
  process.env.CUSTOMER_IO_TRACKING_API_KEY!

export const CUSTOMER_IO_SITE_ID = process.env.CUSTOMER_IO_SITE_ID!
