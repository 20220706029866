import type { FunctionComponent} from 'react';
import { createContext, useContext } from 'react'
import type { LocaleString } from '../lang/allLocales'

export type IOtherLocaleUrlsContext = Record<LocaleString, { url: string }>
export type IOtherLocaleUrlsContextIncomplete = Record<
  LocaleString,
  { url: string } | null
>

const OtherLocaleUrlsContext =
  createContext<null | IOtherLocaleUrlsContextIncomplete>(null)

export const OtherLocaleUrlsContextProvider: FunctionComponent<React.PropsWithChildren<{
  context: IOtherLocaleUrlsContextIncomplete
}>> = ({ children, context }) => {
  return (
    <OtherLocaleUrlsContext.Provider value={context}>
      {children}
    </OtherLocaleUrlsContext.Provider>
  )
}

export const useOtherLocaleUrlsContext = () => {
  return useContext(OtherLocaleUrlsContext)
}
