import { useUserIdWithLoading } from '@dreamstack/auth'
import { trpc } from '@dreamstack/trpc/client'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DEBUG_LOG_CIO } from '../env.config'
import { useGoogleAnalytics } from '../googleAnalytics/GoogleAnalyticsContext'
import { useAdditionalTrackingInfoContext } from './AdditionalTrackingInfoContext'
import { getOrCreateAnonymousId } from './getOrCreateAnonymousId'

export const useTrackEventEffects = () => {
  const { loading: userIdLoading, userId } = useUserIdWithLoading()
  const router = useRouter()
  const additionalInfo = useAdditionalTrackingInfoContext()
  const { mutateAsync: trackEvent } =
    trpc.anonymousTracking.trackEvent.useMutation()
  const [lastEvent, setLastEvent] = useState<string | null>(null)
  const trackedUrlRef = useRef<string | null>(null)

  useEffect(() => {
    if (!router.isReady || userIdLoading) return

    const url = lastEvent ?? router.asPath
    if (url === trackedUrlRef.current) return
    trackedUrlRef.current = url

    if (DEBUG_LOG_CIO) console.log('track cio', { url, additionalInfo })

    const anonymousUserId = getOrCreateAnonymousId()
    const queryParams = router.query

    trackEvent({
      userId: userId,
      anonymousUserId,
      eventName: 'page_view',
      data: { url, additionalInfo, ...queryParams },
    })
  }, [
    additionalInfo,
    lastEvent,
    router.asPath,
    router.isReady,
    router.query,
    trackEvent,
    userId,
    userIdLoading,
  ])

  const triggerEvent = useCallback(
    // TODO: Fix typing
    (evt: any) => {
      setLastEvent(evt.toString())
    },
    [setLastEvent]
  )

  useEffect(() => {
    router.events.on('routeChangeComplete', (e) => {
      triggerEvent(e)
    })
    return () => {
      router.events.off('routeChangeComplete', triggerEvent)
    }
  }, [router.events, triggerEvent])

  const googleAnalytics = useGoogleAnalytics()
  const trackPageView = googleAnalytics?.trackPageView

  useEffect(() => {
    if (!lastEvent) return
    const url = lastEvent
    trackPageView?.({ url, additionalInfo: additionalInfo ?? undefined })
  }, [lastEvent, additionalInfo, trackPageView])
}
