export const MY_PROFILE_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_MYPROFILE?.toLocaleUpperCase() === 'TRUE'
export const MY_MESSAGES_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_MYMESSAGES?.toLocaleUpperCase() === 'TRUE'
export const MY_RECOMMENDATIONS_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_MYRECOMMENADIONS?.toLocaleUpperCase() ===
  'TRUE'
export const LEXICON_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_LEXICON?.toLocaleUpperCase() === 'TRUE'
export const NL_WECHSEL_TATSAECHLICH_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_NL_WECHSEL_TATSAECHLICH?.toLocaleUpperCase() ===
  'TRUE'
export const NBA_FLOW_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_NBA_FLOW?.toLocaleUpperCase() === 'TRUE'

export const FeatureFlags = {
  MY_PROFILE_ENABLED,
  MY_MESSAGES_ENABLED,
  MY_RECOMMENDATIONS_ENABLED,
  LEXICON_ENABLED,
  NL_WECHSEL_TATSAECHLICH_ENABLED,
  NBA_FLOW_ENABLED,
}
