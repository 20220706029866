import type { FullNavBarEntryFragment } from '@dreamstack/investors-graphql'
import { useAnimation } from 'framer-motion'
import type { FunctionComponent} from 'react';
import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { MotionPanel } from './EntryWithSubNavbar'

const StyledMotionPanel = styled(MotionPanel)(() => [
  tw`overflow-y-auto absolute flex justify-center inset-0 z-30 bg-accentroGray-full items-center`,
  `&::-webkit-scrollbar {
  display: none;
}`,
  `-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */`,
])

export const MobileMotionPanel: FunctionComponent<React.PropsWithChildren<{
  entry: FullNavBarEntryFragment
  open: boolean
  mobileNavOpen: boolean
  setFadedOut: React.Dispatch<React.SetStateAction<boolean>>
}>> = ({ entry, open, setFadedOut, mobileNavOpen, children }) => {
  const controls = useAnimation()
  useEffect(() => {
    const animate = async () => {
      if (!mobileNavOpen) return
      if (open) {
        setFadedOut(true)
        controls.start('displayedSub')
      } else {
        setFadedOut(false)
        await controls.start('fadeSubOut')
        await controls.start('hiddenSub')
      }
    }
    animate()
  }, [open, controls, setFadedOut, mobileNavOpen])
  return (
    <StyledMotionPanel
      unmount={false}
      static
      initial="hiddenSub"
      animate={controls}
      variants={{
        fadeSubOut: {
          display: 'flex',
          opacity: 0,
          translateX: '30px',
          transition: {
            duration: 0.15,
          },
        },
        hiddenSub: {
          display: 'none',
          transition: { duration: 0 },
        },
        displayedSub: {
          display: 'flex',
          translateX: 0,
          opacity: 1,
        },
      }}
      key={'panel_' + entry.sys.id}
      transition={{ duration: 0.15 }}
    >
      {children}
    </StyledMotionPanel>
  )
}
