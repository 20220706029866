import { Router } from 'next/router'
import { useEffect } from 'react'

export const useRouteChange = ({
  callback,
  event = 'routeChangeComplete',
}: {
  callback: () => void
  event: 'routeChangeStart' | 'routeChangeComplete' | 'routeChangeError'
}) => {
  useEffect(() => {
    Router.events.on(event, callback)
    return () => {
      Router.events.off(event, callback)
    }
  }, [callback, event])
}
