import type { FullNavBarEntryFragment } from '@dreamstack/investors-graphql'
import { useGetNavBarEntriesQuery } from '@dreamstack/investors-graphql'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

export const useNavBarEntries = (type: 'TopNav' | 'Footer') => {
  const { data } = useGetNavBarEntriesQuery()
  return useMemo(() => {
    let entries = data?.navBarEntries?.items as FullNavBarEntryFragment[]
    if (!entries) return []

    entries = filter(entries, (e) => e.navType === type)
    entries = orderBy(entries, (e) => e.position)

    return entries
  }, [data, type])
}
