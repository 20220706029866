// From: https://usehooks.com/useWindowSize/
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useState } from 'react'
import { ScreenBreakpoints } from '../index'

interface Size {
  width: number | undefined
  height: number | undefined
}

export const useWindowSize = (): Size => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    // Handler to call on window resize
    const handleResize = debounce(() => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }, 50)
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export const useIsSmOrGreater = () => {
  const windowSize = useWindowSize()

  const isSmOrGreater = useMemo(() => {
    return (
      !!windowSize.width && windowSize.width >= ScreenBreakpoints.breakpointSm
    )
  }, [windowSize])
  return isSmOrGreater
}

export const useIsMdOrGreater = () => {
  const windowSize = useWindowSize()

  const isMdOrGreater = useMemo(() => {
    return (
      !!windowSize.width && windowSize.width >= ScreenBreakpoints.breakpointMd
    )
  }, [windowSize.width])
  return isMdOrGreater
}

export const useIsMlOrGreater = () => {
  const windowSize = useWindowSize()

  const isMdOrGreater = useMemo(() => {
    return (
      !!windowSize.width && windowSize.width >= ScreenBreakpoints.breakpointMl
    )
  }, [windowSize.width])
  return isMdOrGreater
}

export const useIsLgOrGreater = () => {
  const windowSize = useWindowSize()

  const isLgOrGreater = useMemo(() => {
    return (
      !!windowSize.width && windowSize.width >= ScreenBreakpoints.breakpointLg
    )
  }, [windowSize.width])
  return isLgOrGreater
}

export const useIsSmOrSmaller = () => {
  const windowSize = useWindowSize()

  const isSmOrSmaller = useMemo(() => {
    return (
      !!windowSize.width && windowSize.width < ScreenBreakpoints.breakpointMd
    )
  }, [windowSize])
  return isSmOrSmaller
}
