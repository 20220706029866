import type {
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode} from '@apollo/client';
import {
  useQuery,
} from '@apollo/client'
import { useLocale } from '@dreamstack/i18n'
import type { DocumentNode } from 'graphql'
import { getDefaultQueryVariables } from '../defaultVars/getDefaultQueryVariables'
import { usePreviewSecret } from '../previewMode/PreviewSecretContext'

export function useQueryWithDefaults<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  optionsInput?: QueryHookOptions<TData, TVariables>
) {
  const locale = useLocale()
  const previewModeSecret = usePreviewSecret()
  const defaultQueryVariables = getDefaultQueryVariables({
    locale,
    preview: !!previewModeSecret,
  })
  const useQueryResult = useQuery<TData, TVariables>(query, {
    ...optionsInput,
    variables: {
      ...(defaultQueryVariables as any),
      ...optionsInput?.variables,
    },
  })

  return useQueryResult
}
