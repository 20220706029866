import { useAuthModalContext, useIsLoggedInWithLoading } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { Search } from '@dreamstack/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { motion, useAnimation } from 'framer-motion'
import map from 'lodash/map'
import { signOut } from 'next-auth/react'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { useUserId } from '../../../auth/src/lib/useUserId'
import { useMyAccentroNavEntry } from '../hooks/useMyAccentroNavEntry'
import { usePageYOffset } from '../hooks/usePageYOffset'
import { useResponsiveHeroVariants } from '../hooks/useResponsiveHeroVariants'
import { useResponsiveNavbarVariants } from '../hooks/useResponsiveNavbarVariants'
import { useRouteChange } from '../hooks/useRouteChange'
import { useScrollDirection } from '../hooks/useScrollDirection'
import { useIsMlOrGreater } from '../hooks/useWindowSize'
import type { HeroSize } from '../index'
import {
  IS_ACCENTRO_INTERNATIONAL,
  IS_ACCENTRO_WEB,
  SHOW_NAV_SEARCH,
  useGetInvestorsLocaleForLink,
} from '../index'
import { ContactHeaderForNavBar } from './ContactHeaderForNavBar'
import { EntryWithSubNavbar, StyledNavEntry } from './EntryWithSubNavbar'
import { LocaleSwitcher } from './LocaleSwitcher'
import { MobileNavBar } from './MobileNavBar'
import { NavBarLogo } from './NavBarLogo'
import { useNavBarEntries } from './useNavBarEntries'

export type NavbarProps = {
  forceDarkTextColor?: boolean
  standalone?: boolean
  isProjectAndProperty?: boolean
  showContactHeader?: boolean
}

type NavbarPropsWithHero = NavbarProps & {
  hasHero: boolean
  heroSize: HeroSize | undefined
}

const NavbarRightElements = () => {
  const locale = useGetInvestorsLocaleForLink()
  return (
    <>
      {SHOW_NAV_SEARCH && (
        <div tw="p-px-8 z-10">
          <Link passHref href="/search">
            <Search />
          </Link>
        </div>
      )}
      <div tw="">
        {IS_ACCENTRO_WEB ? (
          <Link
            href={`https://investors.accentro.de/${locale}`}
            passHref
            legacyBehavior
          >
            <StyledLink
              small
              target="_blank"
              isActive={false}
              tw="z-10 mr-px-20 md:mr-px-40"
            >
              Investor Relations
            </StyledLink>
          </Link>
        ) : (
          <Link
            href={`https://accentro.de/${
              IS_ACCENTRO_INTERNATIONAL ? 'en' : ''
            }`}
            passHref
            legacyBehavior
          >
            <StyledLink
              small
              target="_blank"
              isActive={false}
              tw="z-10 mr-px-20 md:mr-px-40"
            >
              ACCENTRO GmbH
            </StyledLink>
          </Link>
        )}
        <LocaleSwitcher />
      </div>
      {/* {SHOW_NAV_LOGIN && <TopNavLogin />} */}
    </>
  )
}

const NavBarContainer = styled(motion.div)<{
  $forceDarkTextColor: boolean
  $mobileNavOpen: boolean
}>(({ $forceDarkTextColor }) => [
  tw`w-full z-30`,
  !$forceDarkTextColor && tw`text-white`,
  tw`fixed top-0 w-full`,
])

export const activeStyle = css`
  border-bottom: 2px solid transparent;
  border-color: initial;
`

export const subnavActiveStyle = css`
  ${tw`border-b-accentroWhite-full border-b-2`}
`
export const linkHoverStyles = css`
  ${tw`group-hover:border-accentroWhite-full`}
  &:hover {
    ${activeStyle}
  }
`

export const StyledLink = styled.a<{ isActive?: boolean; small?: boolean }>(
  ({ isActive, small }) => [
    small && tw`ml:(text-px-16)`,
    tw`font-semibold text-px-16 border-b-2 border-b-transparent inline-block`,
    linkHoverStyles,
    isActive && activeStyle,
  ]
)

// TODO: refactor, dont't copy from above
export const StyledNavText = styled.span<{ isActive?: boolean }>(
  ({ isActive }) => [
    tw`font-semibold border-b-2 border-transparent text-px-16 inline-block lg:text-px-20`,
    linkHoverStyles,
    isActive && activeStyle,
  ]
)

export const StyledSubNavText = styled.span<{ isActive?: boolean }>(
  ({ isActive }) => [
    tw`text-accentroWhite-full inline-block ml:font-semibold text-px-16 ml:(text-px-16) lg:(text-px-16)`,
    isActive && subnavActiveStyle,
  ]
)

export const StyledButton = styled.button<{
  isActive?: boolean
}>(({ isActive }) => [
  tw`font-semibold text-px-16 border-b-2 border-b-transparent inline-block lg:text-px-20`,
  linkHoverStyles,
  isActive && activeStyle,
])

const NavbarElementWrapper = styled.div<{
  showContactHeader?: boolean
}>(({ showContactHeader }) => [
  tw`hidden md:(block)`,
  showContactHeader && tw`md:mt-px-24`,
])

const NavbarElement = styled(motion.div)<{
  $isAtTop: boolean
  $isScrollingDown: boolean
}>(() => [
  tw`h-px-128 px-px-24 py-px-32 relative sm:height[132px] md:(height[150px] px-px-48) ml:(flex flex-row justify-between items-center) lg:(px-px-64 h-px-196)`,
])

export const NavbarSpaceBlocker = styled.div<{ isHidden?: boolean }>(
  ({ isHidden }) => [
    tw`bg-transparent min-h-px-128 sm:min-height[132px] md:(min-height[150px]) lg:(min-h-px-196)`, // Keep in sync with TransparentSpaceBlocker in Hero.tsx
    isHidden && tw`hidden`,
  ]
)

const StyledNavbarRightElements = styled.div<{ absolute?: boolean }>(
  ({ absolute }) => [
    (IS_ACCENTRO_WEB || (!IS_ACCENTRO_WEB && absolute)) &&
      tw`absolute sm:(top-px-32 right-px-24) md:(top-px-32 right-px-48) ml:(top-px-24 right-px-48) lg:(top-px-32 right-px-64)`,
  ]
)
const EntryWithSubnavbarWrapper = tw.div`flex flex-row flex-1 z-10 items-center mt-px-32 justify-center space-x-px-16 sm:(justify-start) md:(space-x-px-24) ml:(mt-0 justify-center)`
const NavItemWrapper = tw.div`bg-accentroGray-full text-accentroWhite-full px-px-32 w-full`

const NavbarContainerVariants = {
  isAtTop: {
    backgroundColor: '#ffffff00',
    translateY: '0%',
  },
  scrollingUpOverHero: {
    backgroundColor: '#ffffff00',
    translateY: '0%',
  },
  scrollingDown: {
    translateY: '-100%',
  },
  scrollingUp: {
    translateY: '0%',
    backgroundColor: '#ffffff',
  },
  mobileNavOpen: {
    backgroundColor: '#2B2D36',
  },
  mobileNavClosed: {
    backgroundColor: '#ffffff00',
  },
}

export const NavBar: FunctionComponent<
  React.PropsWithChildren<NavbarPropsWithHero>
> = ({
  forceDarkTextColor,
  standalone,
  isProjectAndProperty,
  showContactHeader,
  hasHero,
  heroSize,
}) => {
  const { isScrollingDown } = useScrollDirection()
  const entries = useNavBarEntries('TopNav')
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const { pageYOffset, isAtTop } = usePageYOffset()
  const heroHeights = useResponsiveHeroVariants()
  const navbarElementVariants = useResponsiveNavbarVariants()
  const height = useMemo(() => {
    if (heroSize) {
      return heroHeights[heroSize] - navbarElementVariants.isAtTop.height
    }
  }, [heroHeights, heroSize, navbarElementVariants])

  const hightExist = !!height
  const navigationOverHeroWhileScrollingUp = useMemo(() => {
    return (
      !isScrollingDown &&
      !isAtTop &&
      hasHero &&
      hightExist &&
      pageYOffset <= height
    )
  }, [isScrollingDown, isAtTop, hasHero, hightExist, pageYOffset, height])
  const navigationOutsideHeroWhileScrollingUp = useMemo(() => {
    return (
      !isScrollingDown &&
      !isAtTop &&
      hasHero &&
      hightExist &&
      pageYOffset > height
    )
  }, [isScrollingDown, isAtTop, hasHero, hightExist, pageYOffset, height])

  const topAndScrollingUp =
    !isScrollingDown && !isAtTop && !navigationOverHeroWhileScrollingUp
  const forceDark = forceDarkTextColor || topAndScrollingUp
  const userId = useUserId()
  const { loading, isLoggedIn } = useIsLoggedInWithLoading()
  const t = useTranslation()
  const { setOpen } = useAuthModalContext()

  const myAccentroNavEntry = useMyAccentroNavEntry()

  useRouteChange({
    event: 'routeChangeStart',
    callback: () => setMobileNavOpen(false),
  })

  const controls = useAnimation()

  // TODO: fix typing
  const containerRef = useRef<any>()

  useEffect(() => {
    if (isScrollingDown) setMobileNavOpen(false)
    if (isScrollingDown && !isAtTop) controls.start('scrollingDown')
    else if (
      (!isScrollingDown && !isAtTop && !hasHero) ||
      navigationOutsideHeroWhileScrollingUp
    )
      controls.start('scrollingUp')
    else if (!isScrollingDown && isAtTop) controls.start('isAtTop')
    else if (navigationOverHeroWhileScrollingUp) controls.start('isAtTop')
  }, [
    isAtTop,
    isScrollingDown,
    controls,
    hasHero,
    navigationOutsideHeroWhileScrollingUp,
    navigationOverHeroWhileScrollingUp,
  ])

  useEffect(() => {
    if (mobileNavOpen) controls.start('mobileNavOpen')
    else controls.start('mobileNavClosed')
  }, [mobileNavOpen, controls])

  const isMl = useIsMlOrGreater()

  return (
    <>
      {showContactHeader && <div tw="h-px-32 bg-transparent" />}
      <NavbarSpaceBlocker isHidden={isProjectAndProperty} />

      <NavBarContainer
        ref={containerRef}
        $mobileNavOpen={mobileNavOpen}
        $forceDarkTextColor={forceDark}
        animate={controls}
        transition={{
          type: 'linear',
          duration: 0.2,
          isAtTop: {
            duration: 0.1,
          },
          scrollingDown: {
            ease: 'linear',
          },
          scrollingUp: {
            ease: 'linear',
          },
        }}
        variants={NavbarContainerVariants}
      >
        {showContactHeader && <ContactHeaderForNavBar displayOnTop />}

        <NavbarElementWrapper showContactHeader={showContactHeader}>
          <NavbarElement
            $isAtTop={isAtTop}
            $isScrollingDown={isScrollingDown}
            variants={navbarElementVariants}
          >
            <div>
              <NavBarLogo forceDarkTextColor={forceDark} />
            </div>
            <div tw="flex flex-1">
              {!standalone && (
                <EntryWithSubnavbarWrapper>
                  {map(entries, (entry) => {
                    return (
                      <div key={entry.sys.id} tw="focus-visible:outline-none">
                        <EntryWithSubNavbar
                          tw="focus-visible:outline-none"
                          entry={entry}
                        />
                      </div>
                    )
                  })}
                </EntryWithSubnavbarWrapper>
              )}
              {IS_ACCENTRO_WEB && (
                <div tw="focus-visible:outline-none flex mt-px-32 sm:(justify-end) ml:(mt-0 justify-end width[230px])">
                  {loading ? (
                    <Skeleton animation="wave" tw="w-px-128" />
                  ) : !isLoggedIn ? (
                    <>
                      <div tw="pl-px-12 py-px-12 z-10">
                        <StyledButton
                          isActive={false}
                          tw="z-10"
                          onClick={() => {
                            setOpen(true)
                          }}
                        >
                          {t('accentro:login')}
                        </StyledButton>
                      </div>
                    </>
                  ) : (
                    <EntryWithSubNavbar
                      shouldRoute={userId ? true : false}
                      alignRight
                      tw="focus-visible:outline-none"
                      entry={myAccentroNavEntry}
                      postNode={
                        <>
                          <NavItemWrapper>
                            <div tw="height[2px] bg-accentroWhite-50 mt-px-16 mb-px-16" />
                          </NavItemWrapper>
                          <StyledNavEntry
                            onClick={() =>
                              signOut({
                                callbackUrl: `${window.location.origin}`,
                              })
                            }
                            className="group"
                          >
                            <StyledSubNavText>
                              {t('accentro:logout')}
                            </StyledSubNavText>
                          </StyledNavEntry>
                        </>
                      }
                    />
                  )}
                </div>
              )}
            </div>
            <StyledNavbarRightElements absolute={!isMl}>
              <NavbarRightElements />
            </StyledNavbarRightElements>
          </NavbarElement>
        </NavbarElementWrapper>
        <MobileNavBar
          standalone={standalone}
          controls={controls}
          forceDark={forceDark}
          entries={entries}
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
        />
      </NavBarContainer>
    </>
  )
}
