import { useTranslation } from '@dreamstack/i18n'
import { ChevronLeft, ChevronRight } from '@dreamstack/icons'
import type { FullNavBarEntryFragment } from '@dreamstack/investors-graphql'
import { Popover } from '@headlessui/react'
import type { AnimationControls } from 'framer-motion'
import { motion } from 'framer-motion'
import map from 'lodash/map'
import some from 'lodash/some'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useNavbarDisplaySettings } from '../hooks/useNavbarDisplaySettings'
import { useProjectsSlug } from '../hooks/useProjectsSlug'
import {
  ExtraNavbarEntry,
  ExtraNavbarEntryToInternational,
} from './EntryWithSubNavbar'
import { MobileMotionPanel } from './MobileMotionPanel'
import {
  MobileNavEntriesWrapper,
  NavDivider,
  StyledMobileText,
  mobileFadeOutVariants,
} from './MobileNavBar'
import { subnavActiveStyle } from './NavBar'

const StyledNavEntry = styled.a(() => [
  tw`px-px-32 py-px-12 cursor-pointer block`,
  `&:hover {background-color: rgba(255, 255, 255, 0.025);}`,
])

const StyledMobileSubNavText = styled.span<{ isActive?: boolean }>(
  ({ isActive }) => [
    tw`ml:font-semibold inline-block text-px-16 `,
    isActive && subnavActiveStyle,
  ]
)

// TODO: typings.
export const MobileEntryWithSubNavbar: FunctionComponent<React.PropsWithChildren<{
  controls: AnimationControls
  entry: FullNavBarEntryFragment
  mobileNavOpen: boolean
  setFadedOut: React.Dispatch<React.SetStateAction<boolean>>
}>> = ({ entry, setFadedOut, controls, mobileNavOpen }) => {
  const { asPath } = useRouter()
  const t = useTranslation()
  const isActive =
    !!entry?.staticPage?.slug &&
    (asPath.endsWith(entry.staticPage.slug) ||
      some(entry?.subEntriesCollection?.items, (item) => {
        return item?.staticPage?.slug && asPath.endsWith(item.staticPage.slug)
      }))

  const {
    displayDefaultSubEntry,
    displayProjectsSubEntry,
    displayInternationalSubEntry,
  } = useNavbarDisplaySettings(entry)
  const projectsSlug = useProjectsSlug()
  const [openState, setOpenState] = useState<boolean>(false)

  useEffect(() => {
    if (!mobileNavOpen) setOpenState(false)
  }, [mobileNavOpen])
  const finalHref = entry?.staticPage?.slug
    ? `/${entry?.staticPage?.slug}`
    : entry?.url ?? '#'
  return (
    <Popover tw="focus-visible:outline-none">
      <div>
        <motion.div animate={controls} variants={mobileFadeOutVariants}>
          <button
            onClick={() => setOpenState(true)}
            tw="font-semibold w-full flex justify-between focus-visible:outline-none focus:outline-none"
          >
            <StyledMobileText isActive={isActive}>
              {entry.label}
            </StyledMobileText>
            <ChevronRight tw="w-px-24 h-px-24" />
          </button>
        </motion.div>
        <MobileMotionPanel
          setFadedOut={setFadedOut}
          entry={entry}
          open={openState}
          mobileNavOpen={mobileNavOpen}
        >
          <MobileNavEntriesWrapper>
            <div tw="flex mb-px-16">
              <button
                onClick={() => setOpenState(false)}
                tw="font-semibold flex items-center justify-between focus-visible:outline-none focus:outline-none"
              >
                <ChevronLeft tw="h-px-24 w-px-24 mr-px-8" />
                <StyledMobileText>{entry.label}</StyledMobileText>
              </button>
            </div>
            <NavDivider />
            <div tw="py-px-16">
              {displayDefaultSubEntry && (
                <Link href={finalHref} passHref legacyBehavior>
                  <StyledNavEntry className="group">
                    <StyledMobileSubNavText
                      isActive={
                        !!entry?.staticPage?.slug &&
                        asPath.endsWith(entry.staticPage.slug)
                      }
                    >
                      {t('accentro:navigation.overview')}
                    </StyledMobileSubNavText>
                  </StyledNavEntry>
                </Link>
              )}
              {map(entry?.subEntriesCollection?.items, (subEntry) => {
                const isActive =
                  !!subEntry?.staticPage?.slug &&
                  asPath.endsWith(subEntry.staticPage.slug)
                const finalHref = subEntry?.staticPage?.slug
                  ? `/${subEntry?.staticPage?.slug}`
                  : subEntry?.url ?? '#'
                return (
                  <Link
                    key={subEntry?.sys.id}
                    href={finalHref}
                    passHref
                    legacyBehavior
                  >
                    <StyledNavEntry className="group">
                      <StyledMobileSubNavText isActive={isActive}>
                        {subEntry?.label}
                      </StyledMobileSubNavText>
                    </StyledNavEntry>
                  </Link>
                )
              })}
              {displayProjectsSubEntry && (
                <ExtraNavbarEntry
                  slug={projectsSlug}
                  title={t('accentro:navigation.projects')}
                />
              )}
              {displayInternationalSubEntry && (
                <ExtraNavbarEntryToInternational
                  href={`https://international.accentro.de/`}
                  title={t('accentro:navigation.internationalVisitors')}
                />
              )}
            </div>
          </MobileNavEntriesWrapper>
        </MobileMotionPanel>
      </div>
    </Popover>
  )
}
