import { useEffect, useState } from 'react'
import {
  addScrollListener,
  getScrollTop,
  isBrowser,
  removeScrollListener,
} from '../utils/domUtils'

export const usePageYOffset = (target?: HTMLElement) => {
  const [pageYOffset, setPageYOffset] = useState<number>(0)
  const [targetElement, setTargetElement] = useState<HTMLElement | undefined>()
  useEffect(() => {
    setTargetElement(target)
  }, [target])
  useEffect(() => {
    if (!isBrowser()) setPageYOffset(0)
    if (isBrowser()) {
      const handleScroll = () => {
        setPageYOffset(getScrollTop(targetElement))
      }

      addScrollListener(handleScroll, targetElement)
      return () => removeScrollListener(handleScroll, targetElement)
    }
  }, [targetElement])
  return { pageYOffset, isAtTop: pageYOffset === 0 }
}
