import { useTranslation } from '@dreamstack/i18n'
import type { FullNavBarEntryFragment } from '@dreamstack/investors-graphql'
import filter from 'lodash/filter'
import { useMemo } from 'react'
import { useUserId } from '../../../auth/src/lib/useUserId'
import { FeatureFlags } from '../index'
import { useMyAccentroBaseUrl } from '../my/routing/useMyAccentroBaseUrl'

type FeatureFlaggedFullNavBarEntryFragment = FullNavBarEntryFragment & {
  featureFlag?: keyof typeof FeatureFlags
}

export const useMyAccentroNavEntry = () => {
  const t = useTranslation()
  const userId = useUserId()
  const myAccentroBaseUrl = useMyAccentroBaseUrl()
  return useMemo(() => {
    const items: FeatureFlaggedFullNavBarEntryFragment[] = userId
      ? [
          {
            sys: { id: 'overview' },
            staticPage: { slug: `${myAccentroBaseUrl}` },
            label: 'Dashboard',
          },
          {
            featureFlag: 'NBA_FLOW_ENABLED',
            sys: { id: 'documents' },
            staticPage: {
              slug: `${myAccentroBaseUrl}/documents`,
            },
            label: t('accentro:my.documents'),
          },
          {
            featureFlag: 'MY_PROFILE_ENABLED',
            sys: { id: 'profile' },
            staticPage: { slug: `${myAccentroBaseUrl}/profile` },
            label: t('accentro:my.myProfile.profile'),
          },
          {
            featureFlag: 'MY_MESSAGES_ENABLED',
            sys: { id: 'messages' },
            staticPage: { slug: `${myAccentroBaseUrl}/messages` },
            label: t('accentro:messages.title'),
          },
        ]
      : []

    const filteredUrls = filter(items, (item) => {
      if (!item.featureFlag) return true
      else if (FeatureFlags[item.featureFlag]) return true
      else return false
    })

    return {
      label: t('accentro:myAccentro'),
      staticPage: { slug: `${myAccentroBaseUrl}` },
      subEntriesCollection: {
        items: filteredUrls,
      },
      sys: { id: 'myAccentro' },
    } as FullNavBarEntryFragment
  }, [myAccentroBaseUrl, t, userId])
}
