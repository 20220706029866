import { useLocale } from '@dreamstack/i18n'

const comingSoonSlugsForLocale = {
  de: 'comingsoon',
  en: 'comingsoon',
}

export const useComingSoonSlug = () => {
  // Coming soon slug is the same for all locales, should we want to change this
  // at later stage, than we can add a locale specific slug
  const locale = useLocale()

  return comingSoonSlugsForLocale[locale]
}
