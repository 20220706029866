import { useLocale } from '@dreamstack/i18n'

const projectSlugsForLocale = {
  en: 'projects',
  de: 'projekte',
}

export const useProjectsSlug = () => {
  const locale = useLocale()
  return projectSlugsForLocale[locale]
}
