import type { FullNavBarEntryFragment } from '@dreamstack/investors-graphql'
import includes from 'lodash/includes'
import { useMemo } from 'react'
import { LEXICON_ENABLED } from '..'

export const REAL_ESTATE_NAVBAR_ENTRY_CONTENFUL_SYS_ID =
  '5Ohpq9PZ8S89IIBOoMWJHy'

export const KNOWLEDGE_NAVBAR_CONTENTFUL_SYS_ID = '34IQtbod5yUEv22DoWznvK'
// export const COMPANY_NAVBAR_ENTRY_CONTENTFUL_SYS_ID = '1lPusrIovR29Tcu6dGzUuf'

export const useNavbarDisplaySettings = (entry: FullNavBarEntryFragment) => {
  const displaySettings = useMemo(() => {
    return {
      displayDefaultSubEntry:
        !includes(
          entry?.subEntriesCollection?.items.flatMap(
            (i) => i?.staticPage?.slug
          ),
          entry.staticPage?.slug
        ) && entry?.staticPage?.slug !== 'my',
      displayProjectsSubEntry:
        entry.sys.id === REAL_ESTATE_NAVBAR_ENTRY_CONTENFUL_SYS_ID,
      displayLexiconSubEntry:
        LEXICON_ENABLED && entry.sys.id === KNOWLEDGE_NAVBAR_CONTENTFUL_SYS_ID,
      displayInternationalSubEntry:
        entry.sys.id === REAL_ESTATE_NAVBAR_ENTRY_CONTENFUL_SYS_ID,
    }
  }, [entry])
  return displaySettings
}
